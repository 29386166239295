import React, { useState } from "react";
import "./CoinToss.css";
import PopupModal from "./PopupModal";

let noHeads = 0;
let noTails = 0;

function CoinToss() {
  const [face, setFace] = useState("Head");
  const [animate, setAnimate] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleClick = () => {
    setFace("Head");
    setAnimate(true);

    setTimeout(() => {
      setAnimate(false);
      const random = Math.random();
      if (random <= 0.5) {
        setFace("Head");
        noHeads += 1;
      } else {
        setFace("Tail");
        noTails += 1;
      }
      setPopupMessage(""); 
      setShowPopup(true);
    }, 4000);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setShowEmailInput(false);
  };

  const handleYesClick = () => {
    setShowEmailInput(true);
  };

  const handleNoClick = () => {
    setShowPopup(false);
    setShowEmailInput(false);
  };

  const handleSaveResultsClick = () => {
    setPopupMessage("Do you want to save the results?");
    setShowPopup(true);
  };

  return (
    <div className="Container">
      <div className="container-wrap">
        <div className="container">
          <div className="row" style={{ display: "flex" }}>
            <div className="col-lg-3 col-xl-3"></div>

            <div className="col-lg-12 col-xl-12">
              <h5 className="h5  mb-5  text-center" style={{marginTop:"-25px"}}>
                Wanna Save The Results ?
                <span
                  onClick={handleSaveResultsClick}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  Click Here
                </span>
              </h5>
              <div
                className="p-5 rounded-3"
                style={{ backgroundColor: "rgb(255, 255, 255)", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <div className="row">
                  <div
                    className="col-lg-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p className="game-title">Heads or Tails</p>
                    <span className="score-text">{`Heads: ${noHeads} | Tails: ${noTails}`}</span>

                    <div
                      className={`CoinContainer ${
                        animate ? "CoinContainerAnimate" : ""
                      }`}
                    >
                      <div
                        className={`Coin ${animate ? "Head" : ""}`}
                        id="Head"
                        style={
                          face === "Head"
                            ? { backgroundColor: "black", border: "3px dotted white" }
                            : { backgroundColor: "brown", border: "3px dotted white" }
                        }
                      >
                        <p>{face === "Head" ? "Head" : "Tail"}</p>
                      </div>
                      <div className={`Coin ${animate ? "Tail" : ""}`} id="Tail">
                        <p>Tail</p>
                      </div>
                    </div>

                    <button
                      className="button"
                      onClick={handleClick}
                      style={{ borderRadius: "3px", backgroundColor: "rgb(252, 169, 3)", color: "black" }}
                    >
                      Flip The Coin !!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <PopupModal
          onClose={handleClosePopup}
          onYesClick={handleYesClick}
          onNoClick={handleNoClick}
          showEmailInput={showEmailInput}
          message={popupMessage}
        />
      )}
    </div>
  );
}

export default CoinToss;
