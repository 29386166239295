import React, { useState } from "react";
import "./PopupModal.css";
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import elorcaLogo from '../img/elorca.ico'; 

const PopupModal = ({ onClose, onYesClick, onNoClick, showEmailInput, message }) => {
  const [popupContent, setPopupContent] = useState({
    title: "Don't miss out !!",
    description: "Experience the full potential! Sign up now for exclusive features, handcrafted to amplify your journey with us. Don't settle for less."
  });

  const [hideInputs, setHideInputs] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSignUpClick = () => {
    if (!email) {
      setError("Email is required");
      return;
    }
    setPopupContent({
      title: "YYYaaayyyy !!!",
      description: "Thank you for signing up! Enjoy exclusive features and more."
    });
    setHideInputs(true);
    setTimeout(() => {
      window.location.replace("https://www.elorca.com/login/");
    }, 500);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(""); 
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <img src={elorcaLogo} alt="Logo" className="popup-logo" /> 
        <div className="popup-text">
          <p className="popup-title">{popupContent.title}</p>
          <p className="popup-description">{popupContent.description}</p>
          <p className="popup-desc">Unlimited Moves</p>
          <p className="popup-desc" style={{ marginTop: "-20px" }}>More Games</p>
          <p className="popup-desc" style={{ marginTop: "-20px" }}>Wanna Save The Results</p>
        </div>
        {showEmailInput && !hideInputs && (
          <div className="popup-input">
            <input
              type="text"
              className="form-control"
              placeholder="Enter email address"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              style={{ borderRadius: "20px 0 0 20px" }}
              value={email}
              onChange={handleEmailChange}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              id="button-addon2"
              style={{ borderRadius: "0 20px 20px 0", backgroundColor: "rgb(252, 169, 3)", color: "black" }}
              onClick={handleSignUpClick}
            >
              Sign Up
            </button>
          </div>
        )}
        {error && <p className="error-text" style={{ color: "red" }}>{error}</p>}
        {!hideInputs && (
          <div className="popup-buttons">
            <button className="popup-button" onClick={onYesClick}>Yes</button>
            <button className="popup-button" onClick={onNoClick}>No, I'm good with the basics.</button>
          </div>
        )}
        <i
          className="fa-solid fa-circle-xmark"
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            fontSize: '24px',
            color: 'black'
          }}
        ></i>
      </div>
    </div>
  );
};

export default PopupModal;
