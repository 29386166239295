import React from "react";
import CoinToss from "../components/CoinToss";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

function Home() {
  return (
    <>
      <Navbar />
      <div className="row justify-content-center">
        <div className="col-xl-6 col-lg-8 text-center">
              <h3 className="h2 fw-bold mb-3 mt-4">Toss The Coin</h3>
        </div>
        </div>
      <CoinToss />
      <Footer />
    </>
  );
}

export default Home;
